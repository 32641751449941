import { useRef, useState } from "react";

import { ChevronDown, ChevronUp } from "@vgno/icons";
import { classnames } from "@vgno/utils";

import { links } from "./constants";

import styles from "./MastheadExtension.module.css";

const MastheadExtension = ({
  activeLink,
}: {
  activeLink?: string | undefined;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const meastheadRef = useRef<HTMLDivElement | null>(null);

  return (
    <header
      className={classnames(styles.root, !isExpanded && styles.isClosed)}
      ref={meastheadRef}
    >
      <div className={styles.topNav}>
        <div className={styles.mainInfo}>
          <h1 className={styles.sectionTitle}>VG-Lista</h1>
          {activeLink && (
            <p className={classnames(styles.subSectionTitle)}>{activeLink}</p>
          )}
        </div>
        <button
          className={classnames(styles.toggle, styles.mobile)}
          onClick={() => setIsExpanded((state) => !state)}
        >
          {isExpanded ? <ChevronUp /> : <ChevronDown />}
        </button>
      </div>
      <nav>
        <ul className={classnames(styles.nav)}>
          {links.map(({ path, text }) => (
            <li key={text + path} style={{ display: "contents" }}>
              <a
                className={classnames(
                  styles.link,
                  text === activeLink && styles.activeLink,
                )}
                href={path}
              >
                {text}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};
export default MastheadExtension;
