export const links = [
  { path: "/vglista", text: "Forside" },
  { path: "/vglista/livestream", text: "Livestream" },
  { path: "/vglista/artister", text: "Artister" },
  { path: "https://www.vglista.no/topplister", text: "Topplistene" },
  {
    path: "/vglista/informasjon",
    text: "Praktisk informasjon",
  },
  {
    path: "/vglista/informasjon/golden-circle",
    text: "Golden Circle",
  },
  {
    path: "/vglista/informasjon/ny-paa-konsert",
    text: "Ny på konsert",
  },
  {
    path: "/vglista/informasjon/festivalvett",
    text: "Festivalvett",
  },
  {
    path: "/vglista/informasjon/omraadekart",
    text: "Område",
  },
  {
    path: "/tag/vg-lista/",
    text: "Artikler og videoklipp",
  },
];
